<template>
  <div
    id="supportForm"
    ref="supportForm"
    class="form container is-fluid box shadow"
  ></div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import loadJs from '../../lib/loadJs';

export default defineComponent({
  data() {
    return {
      hubspotScriptLoaded: false,
    };
  },
  watch: {
    hubspotScriptLoaded: {
      handler(newVal) {
        if (newVal) {
          // eslint-disable-next-line no-undef
          hbspt.forms.create({
            portalId: '2568241',
            formId: 'b7977850-9176-4183-8334-38cdf839afc1',
            target: '#supportForm',
            css: '',
          });
          this.$nextTick(() => {
            const iframeParent = document.getElementsByClassName(
              'hs-form-iframe',
            );
            const iframeParent2 = document.querySelectorAll('iframe');
          });
        }
      },
    },
  },
  created() {
    loadJs(
      '//js.hsforms.net/forms/shell.js',
      () => {
        this.hubspotScriptLoaded = true;
      },
      document.body,
      false,
      true,
    );
  },
});
</script>

<style lang="scss" scoped>
.form {
  padding: 5em 5em 5em 5em;
  overflow-x: auto;
  border-radius: 5px;
  width: 95vw;
  margin-top: 1em;
  -webkit-box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.71);
  -moz-box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.71);
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.71);
}
</style>
