export default function loadJs(
  url,
  implementationCode,
  location,
  async = false,
  defer = false,
) {
  const scriptTag = document.createElement('script');
  scriptTag.src = url;
  scriptTag.async = async;
  scriptTag.defer = defer;
  scriptTag.onload = implementationCode;
  scriptTag.onreadystatechange = implementationCode;
  location.appendChild(scriptTag);
}
